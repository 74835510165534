import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css'

import MainPage from './routes/MainPage'
import StudentPage from './routes/Student'
import TeacherPage from './routes/Teacher'
import PrincipalPage from './routes/Principal'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
 
function App() {

  return (

      <div className="App">
        <ToastContainer autoClose={1700}
        />
        <Router>
          <Routes>
            <Route path="/" element={<MainPage/>} />
            <Route path="/student" element={<StudentPage/>} />
            <Route path="/teacher" element={<TeacherPage/>} />
            <Route path="/principal" element={<PrincipalPage/>} />
          </Routes>
        </Router>
      </div>

  )
}

export default App
