import React, { createContext, useContext, useState } from 'react'

const StudentIdContext = createContext()

export const StudentIdProvider = ({ children }) => {
    const [id, setId] = useState(1)

    const changeStudentId = (newId) => {
        setId(newId)
    }

    return (
        <StudentIdContext.Provider value={{ id, changeStudentId }}>
            {children}
        </StudentIdContext.Provider>
    )
}

export const useStudentId = () => {
    return useContext(StudentIdContext)
}