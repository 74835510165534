import React, {useState, useEffect} from "react"
import { GetAverage } from "../api/calls"


function AverageGrade(props) {


    function renderColor(num) {
        if (num < 1.5) return "#4CAF50"
        if (num < 2.5) return "#8BC34A"
        if (num < 3.5) return "#FBC02D"
        if (num < 4.5) return "#FF9800"
        if (num < 5.5) return "#F44336"
        else return "#9c0202"
    }

    return(
        <>
            {props.average === null ? null : (
                <>
                    {props.mode === "user" ? (
                        <h2> ⌀ {props.average.toFixed(2)}</h2>
                    ) : (
                        <div className="Average">
                            <h2> ⌀ </h2> 
                            <h5 style={{color: renderColor(props.average)}}>{props.average.toFixed(2)}</h5>
                        </div>
                    )}
                </>
            )}
        </>
    )
}

export default AverageGrade