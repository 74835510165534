import React, {useState, useEffect} from "react";
import { Table, Button } from "react-bootstrap"
import Icon from "@mdi/react"
import { mdiPencilBoxOutline, mdiCheck } from "@mdi/js"
import ScheduleManagement from "./ScheduleManagement"
import { GetSchedule } from "../api/calls";

function ScheduleViewer(props) {

    const [schedule, setSchedule] = useState([])
    const days = ['Mo', 'Tu', 'We', 'Th', 'Fr']
    const hours = Array.from({ length: 9 }, (_, i) => i + 1)
    const times = [" ", "8:00 - 8:45", "8:50 - 9:35", "9:40 - 10:25", "10:45 - 11:30",
        "11:35 - 12:20", "12:25 - 13:10", "13:15 - 14:00", "14:05 - 14:50", "14:55 - 15:40"]

    const [isEditorOn, setIsEditorOn] = useState(false)
    const [subjects, setSubjects] = useState(1)

    useEffect(() => {
        const fetchSchedule = async () => {
            const data = await GetSchedule()
            if (data)
                setSchedule(data) 
        }
        fetchSchedule()
    }, [subjects])

    function translateDay(day) {
        if (day === "Mo") return "Montag"
        if (day === "Tu") return "Dienstag"
        if (day === "We") return "Mittwoch"
        if (day === "Th") return "Donnerstag"
        else return "Freitag"
    }


    return(
        <div className= {props.role === "Principal" ? ("PrincipalSchedule"):("Subjects")}>
            <div className="PrincipalHeader">
                {props.role === "Principal" ? (<h2>Stundenplan</h2>):(<h1>Stundenplan</h1>)}
                    {props.role === "Principal" && !isEditorOn ? (
                        <Button 
                            variant="outline-secondary" 
                            style={{ padding: "5px"}}
                            onClick={() => setIsEditorOn(true)}
                        >
                            <Icon path={mdiPencilBoxOutline} size={2} />
                        </Button>
                    ) : props.role === "Principal" ? (
                        <Button 
                            variant="success" 
                            style={{ padding: "5px"}}
                            onClick={() => setIsEditorOn(false)}
                        >
                            <Icon path={mdiCheck} size={2} />
                        </Button>
                    ):(null)}
            </div>
            
            <Table bordered responsive>
                <thead>
                    <tr>
                        <th></th>
                        {hours.map((hour) => (
                            <th key={hour}>
                                {hour}
                            </th>
                        ))}
                    </tr>
                    <tr>
                        {times.map(time => (
                            <th key={time}>
                                {time}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {days.map((day) => (
                        <tr key={day} style={{height: "4rem"}}>
                            <td style={{width: "10%"}}>{translateDay(day)}</td>
                            {hours.map((hour) => {
                                const scheduleItem = schedule[day]?.schedule.find(item => item.Hour === hour);
                                return (
                                    <td key={hour} style={{width: "10%", height: "8rem", backgroundColor: scheduleItem ? `${scheduleItem.Subject.color}85` : 'transparent' }}>
                                        {scheduleItem ? <h2><b>{scheduleItem.Subject.short_name}</b></h2> : ''}
                                        {scheduleItem ? <p>{scheduleItem.Subject.name}</p> : ''}
                                        {isEditorOn ? (
                                            <div className="ScheduleEditor">
                                                {scheduleItem ? (
                                                    <>
                                                        <br />
                                                        <ScheduleManagement scheduleId={scheduleItem.ID} day={day} hour={hour} mode={"Edit"} onUpdate={() => setSubjects(subjects + 1)}/>
                                                        
                                                    </>
                                                ):(
                                                    <>
                                                        <br />
                                                        <ScheduleManagement day={day} hour={hour} mode={"Add"} onUpdate={() => setSubjects(subjects + 1)}/>
                                                    </>
                                                )}
                                            </div>
                                        ):(null)}
                                    </td>
                                );
                            })}
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    )
}

export default ScheduleViewer