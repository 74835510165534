import React, {useState, useEffect} from "react"
import { Form, Button, Modal, FloatingLabel} from "react-bootstrap"
import moment from "moment"
import Icon from '@mdi/react'
import { mdiPlusBoxOutline, mdiPencilBoxOutline } from '@mdi/js'
import { NewStudent, EditStudent, DeleteStudent } from "../api/calls"

function StudentManagement(props) {

    const [show, setShow] = useState(false)

    const handleClose = () => {
        setShow(false)
        setName("")
        setSurname("")
        setBirthday("")
        setAddress("")
    }
    const handleShow = () => setShow(true)

    const [name, setName] = useState("")
    const [surname, setSurname] = useState("")
    const [birthday, setBirthday] = useState("")
    const [address, setAddress] = useState("")

    useEffect(() => {
        if (props.mode==="Edit") {
            setName(props.student.name);
            setSurname(props.student.surname);
            setBirthday(props.student.birthday)
            setAddress(props.student.address)
        }
    }, [props.student]);

    const newStudent = async (name, surname, birthday, address) => {
        try {
            await NewStudent(name, surname, birthday, address)
        } finally {
            handleClose()
            props.onUpdate()
        }   
    }
    
    const editStudent = async (name, surname, birthday, address) => {
        let id = props.student.id
        try {
            await EditStudent(id, name, surname, birthday, address)
        } finally {
            handleClose()
            props.onUpdate()
        }     
    }

    const deleteStudent = async () => {
        let id = props.student.id
        try {
            await DeleteStudent(id)
        } finally {
            handleClose()
            props.onUpdate()
        }
    }


    return(
        <div className="NewStudent">
            {props.mode === "Create" ? (
                <Button 
                    style={{ padding: "5px", margin: "0 7px 0 0"}} 
                    variant="success" 
                    onClick={handleShow}
                >
                    <Icon path={mdiPlusBoxOutline} size={2} />
                </Button>
            ):(
                <Button 
                    style={{ padding: "5px"}} 
                    variant="outline-secondary" 
                    onClick={handleShow}
                >
                    <Icon path={mdiPencilBoxOutline} size={2} />
                </Button>
            )}
            <Modal show={show} onHide={handleClose} centered size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        {props.mode === "Create" ? ("Create a new student"):("Editing Student")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <FloatingLabel label="Name">
                            <Form.Control 
                                type="text" 
                                placeholder="Name" 
                                value={name} 
                                onChange={(e) => setName(e.target.value)} 
                                autoFocus
                            />
                        </FloatingLabel> <br />
                        <FloatingLabel label="Surname">
                            <Form.Control 
                                type="text" 
                                placeholder="Surname" 
                                value={surname} 
                                onChange={(e) => setSurname(e.target.value)}
                            />
                        </FloatingLabel> <br />
                        <FloatingLabel label="Birthday">
                            <Form.Control 
                                type="date" 
                                placeholder="Birthday" 
                                value={moment(birthday).format("YYYY-MM-DD")} 
                                onChange={(e) => setBirthday(e.target.value)}
                            />
                        </FloatingLabel> <br />
                        <FloatingLabel label="Address">
                            <Form.Control 
                                type="text" 
                                placeholder="Address" 
                                value={address} 
                                onChange={(e) => setAddress(e.target.value)}
                            />
                        </FloatingLabel>
                    </Form>
                    <br />
                    {props.mode === "Edit" ? (
                        <Button 
                            variant="outline-danger" 
                            onClick={() => deleteStudent(props.student.id)}
                        >
                            Delete student
                        </Button>
                    ):(null)}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleClose}>
                        Cancel
                    </Button>
                    {props.mode === "Create" ? (
                        <Button variant="success" onClick={() => newStudent(name, surname, birthday, address)}>
                            Create
                        </Button>
                    ):(
                        <Button variant="success" onClick={() => editStudent(name, surname, birthday, address)}>
                            Save changes
                        </Button>
                    )}
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default StudentManagement