import React, {useState, useEffect} from "react"
import Icon from '@mdi/react'
import { mdiFileEditOutline, mdiDeleteOutline } from '@mdi/js'
import { Button, Modal, Form } from "react-bootstrap"
import GradeWeight from "./GradeWeight"
import GradeSelector from "./GradeSelector"
import { DeleteGrade, UpdateGrade } from "../api/calls"


function EditGrade(props) {

    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)

    const [description, setDescription] = useState("")
    const [grade, setGrade] = useState(0)
    const [weight, setWeight] = useState(0)

    useEffect(() => {
        if (props.grade) {
            setDescription(props.grade.description || "")
            setGrade(props.grade.grade || 0)
            setWeight(props.grade.weight || 0)
        }
    }, [props.grade])

    const deleteGrade = async (id) => {
        try {
            await DeleteGrade(id)
        } finally {
            handleClose()
            props.onUpdate()
        }
    }

    const editGrade = async (id, description, grade, weight) => {
        try {
            await UpdateGrade(id, description, grade, weight)
        } finally {
            handleClose()
            props.onUpdate()
        }
    }

    return(
        <div className="EditGrade">
            <button onClick={handleShow} style={{width: "5.5rem", margin: "5px"}}>
                <Icon path={mdiFileEditOutline} size={1} color="gray"/>
            </button>
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Edit grade</Modal.Title>
                </Modal.Header> 
                <Modal.Body>
                    <Form>
                        <Form.Label>Description</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder={props.grade.name}
                                value= {description}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                        <br/>
                        <Form.Label>Weight</Form.Label>
                        <br/>
                        <GradeWeight setWeight={(num) => setWeight(num)} weight={weight}/>
                        <br/><br/>
                        <Form.Label>Grade</Form.Label>
                        <br/>
                        <GradeSelector grade={Math.round(grade)} mode={"one"} onChange={(num) => setGrade(num)}/>
                    </Form>
                    <br/>
                    <Button variant="outline-danger" onClick={() => {deleteGrade(props.grade.id)}}>
                        <Icon path={mdiDeleteOutline} size={1}/>
                        Delete this grade
                    </Button>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Cancel</Button>
                    <Button variant="success" onClick={() => {editGrade(props.grade.id, description, grade, weight)}}>Save</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default EditGrade