import React, { useState, useEffect } from "react"
import {Accordion, Spinner} from 'react-bootstrap'
import TeacherGrades from "./TeacherGrades"
import CreateGrade from "./CreateGrade"
import CreateMultipleGrades from "./CreateMultipleGrades"
import AverageGrade from "./AverageGrade"

function TeacherSubjects() {

    const [data, setData] = useState([])
    const [gradeUpdate, setGradeUpdate] = useState(1)

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`https://todoapi.sivakm.com/api/grade/teacherData`)
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`)
                }
                const result = await response.json()
                setData(result)
                return
            } catch (error) {
                console.error('Error fetching data:', error)
            }
        }
        fetchData()
    }, [gradeUpdate])




    return(
        <div className="TeacherSubjects">
    <h1>Fächer</h1>
    {data.length !== 0 ? (
        <Accordion defaultActiveKey="0" className="Accordion">
            {data.map(subject => (
                <Accordion.Item key={subject.id} eventKey={subject.id} className="AccordionItem">
                    <Accordion.Header>
                        <h1 
                            className="SubjectName" 
                            style={{backgroundColor: `${subject.color}99`, marginRight: "1rem"}}
                        >
                            {subject.short_name}
                        </h1>
                        <h2>{subject.name}</h2>
                    </Accordion.Header>
                    <Accordion.Body>
                        <CreateMultipleGrades 
                            subject={subject.id} 
                            students={subject.students} 
                            subjectName={subject.name} 
                            onUpdate={() => setGradeUpdate(gradeUpdate + 1)}
                        />
                        {subject.students.map(user => (
                            <div key={user.id} className="TeacherCardContainer">
                                <div className="NameContainer">
                                    <h6>{user.surname}, {user.name}</h6>
                                </div>
                                <div className="AverageContainer">
                                    <AverageGrade 
                                        studentId={user.id} 
                                        subjectId={subject.id} 
                                        average={user.weightedAverage}
                                    />
                                </div>
                                <div className="TeacherCards">
                                    <TeacherGrades 
                                        subject={subject.id} 
                                        student={user.id} 
                                        grades={user.grades} 
                                        onUpdate={() => setGradeUpdate(gradeUpdate + 1)}
                                    />
                                </div>
                                <div className="ButtonContainer">
                                    <CreateGrade 
                                        subject={subject.id} 
                                        student={user.id} 
                                        subjectName={subject.name} 
                                        studentName={`${user.surname} ${user.name}`} 
                                        onUpdate={() => setGradeUpdate(gradeUpdate + 1)}
                                    />
                                </div>
                            </div>
                        ))}
                    </Accordion.Body>
                </Accordion.Item>
            ))}
        </Accordion>
    ) : (
        <Spinner style={{ width: '5rem', height: '5rem', marginTop:"10rem" }}/>
    )}
</div>

    )
}

export default TeacherSubjects