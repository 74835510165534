import React, { useState, useEffect } from "react"
import {Table} from 'react-bootstrap'
import SubjectManagement from "./SubjectManagement"
import { SubjectList } from "../api/calls"




function PrincipalSubjects() {

    const [subjects, setSubjects] = useState([])
    const [newSubject, setNewSubject] = useState(0)

    useEffect(() => {
        const fetchSubjects = async () => {
            const data = await SubjectList()
            if (data) setSubjects(data)
        }
        fetchSubjects()
    }, [newSubject])


    return(
        <div className="PrincipalSubjects">
            <div className="PrincipalHeader">
                <h2>Subjects</h2>
                <SubjectManagement onUpdate={() => setNewSubject(newSubject + 1)} mode="Create"/> 
            </div>
            
            {subjects.length !=0  ? (
                <Table hover> 
                    <tbody>
                        {subjects.map(subject => (
                            <tr key={subject.id}>
                                <td style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                    <div className="SmallerSubjectIcon">
                                        <h2 className="SmallerSubjectName" style={{backgroundColor: `${subject.color}99`}}>{subject.short_name}</h2>
                                        <h4>{subject.name}</h4>
                                    </div>
                                    <div>
                                        <SubjectManagement onUpdate={() => setNewSubject(newSubject + 1)} mode="Edit" subject={subject}/>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            ):(null)}
        </div>
    )
}

export default PrincipalSubjects