import React from "react"
import {Button, ButtonGroup} from "react-bootstrap"

function GradeWeight(props) {
    return(
        <ButtonGroup style={{width: "100%"}}>
            {Array.from({ length: 10 }, (_, i) => i + 1).map((num) => (
                <Button
                    key={num}
                    variant={props.weight === num ? "secondary" : "light"}
                    style={{ fontSize: `${16 + num}px` }}
                    onClick={() => props.setWeight(num)} 
                >
                    {num}
                </Button>
            ))}
        </ButtonGroup>
    )
}

export default GradeWeight