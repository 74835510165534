import React, {useState} from "react"
import { Form, Button, Modal, FloatingLabel } from "react-bootstrap"
import Icon from '@mdi/react';
import { mdiPlusBoxOutline } from '@mdi/js';
import GradeWeight from "./GradeWeight";
import GradeSelector from "./GradeSelector";
import { CreateNewGrade } from "../api/calls";
import { toast } from "react-toastify";

function CreateGrade(props) {

    const [show, setShow] = useState(false)
    
    const handleShow = () => setShow(true)
    const handleClose = () => {
        setShow(false)
        setGrade(0)
        setDescription("")
        setWeight(0)
    }
    

    const [grade, setGrade] = useState(0)
    const [weight, setWeight] = useState(0)
    const [description, setDescription] = useState("")

    const createGrade = async (student, subject, description, grade, weight) => {
        try {
            await CreateNewGrade(student, subject, description, grade, weight)
        } finally {
            handleClose()
            props.onUpdate()
            toast.success('Neue Note wurde hinzugefügt!', {position: "top-center"});
        }
    }

    return(
        <div className="NewGrade">
            <Button variant="success" style={{height: "3rem"}} onClick={handleShow}>
                <Icon path={mdiPlusBoxOutline} size={1} />
            </Button>

            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Neue Note</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Subject:<b> {props.subjectName}</b></p>
                    <p>Student:<b> {props.studentName}</b></p>
                    <Form>
                        <Form.Group>
                            <FloatingLabel label="Description">
                                <Form.Control 
                                    type="text" 
                                    placeholder="example: Test" 
                                    onChange={(e) => setDescription(e.target.value)}
                                />
                            </FloatingLabel>
                        </Form.Group>
                        <br/>
                        <p>Weight</p>
                        <GradeWeight setWeight={(num) => setWeight(num)} weight={weight}/>
                        <br/><br/>
                        <p>Grade</p>
                        <GradeSelector grade={grade} mode={"one"} onChange={(num) => setGrade(num)}/>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleClose}>Back</Button>
                    <Button variant="success" onClick={() => createGrade(props.student, props.subject, description, grade, weight)}>Add</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default CreateGrade