import React from "react";
import UserSwitcher from "../components/UserSwitcher"
import { StudentIdProvider, useStudentId } from "../components/StudentIdContext"
import StudentSubjectList from "../components/StudentSubjectList"
import ScheduleViewer from "../components/ScheduleViewer";


function StudentPage() {

    const {id} = useStudentId()

    return (
        <div className="StudentPage">
            <div className="Header">
                <h1 className="SmallLogo">NoteBuzz</h1>
                <UserSwitcher />
            </div>
            <StudentSubjectList id={id}/>
            <ScheduleViewer />
        </div>
    )
}

export default function WrappedStudentPage() {
    return (
        <StudentIdProvider>
            <StudentPage />
        </StudentIdProvider>
    )
}