import React, {useState, useEffect} from "react"
import { Form, Button, Modal, FloatingLabel} from "react-bootstrap"
import Icon from '@mdi/react'
import { mdiPlusBoxOutline, mdiPencilBoxOutline } from '@mdi/js'
import { NewSubject, EditSubject, DeleteSubject } from "../api/calls"

function SubjectManagement(props) {

    const [show, setShow] = useState(false)

    const handleClose = () => {
        setShow(false)
        setName("")
        setShortName("")
        setColor("#ffcc00")
    }
    const handleShow = () => setShow(true)

    const [name, setName] = useState("")
    const [shortName, setShortName] = useState("")
    const [color, setColor] = useState("#ffcc00")

    useEffect(() => {
        if (props.mode==="Edit") {
            setName(props.subject.name)
            setShortName(props.subject.short_name)
            setColor(props.subject.color)
        }
    }, [props.subject, show]);

    const newSubject = async (name, short_name, color) => {
        try {
            await NewSubject(name, short_name, color)
        } finally {
            handleClose()
            props.onUpdate()
        }
    }
    
    const editSubject = async (name, short_name, color) => {
        let id = props.subject.id
        try {
            await EditSubject(id, name, short_name, color)
        } finally {
            handleClose()
            props.onUpdate()
        }
}

    const deleteSubject = async () => {
        let id = props.subject.id
        try {
            await DeleteSubject(id)
        } finally {
            handleClose()
            props.onUpdate()
        }
    }


    return(
        <div className="NewSubject">
            {props.mode === "Create" ? (
                <Button 
                    style={{ padding: "5px", margin: "0 7px 0 0"}} 
                    variant="success" 
                    onClick={handleShow}
                >
                    <Icon path={mdiPlusBoxOutline} size={2} />
                </Button>
            ):(
                <Button 
                    style={{ padding: "5px"}}
                    variant="outline-secondary" 
                    onClick={handleShow}
                >
                    <Icon path={mdiPencilBoxOutline} size={2} />
                </Button>
            )}
            <Modal show={show} onHide={handleClose} centered size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        {props.mode === "Create" ? ("Create a new subject"):("Editing Subject")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <FloatingLabel label="Name">
                            <Form.Control 
                                type="text" 
                                placeholder="Name" 
                                value={name} 
                                onChange={(e) => setName(e.target.value)} 
                                autoFocus
                            />
                        </FloatingLabel> <br />
                        <FloatingLabel label="Short name">
                            <Form.Control 
                                type="text" 
                                placeholder="ShortName" 
                                value={shortName} 
                                onChange={(e) => setShortName(e.target.value)}
                            />
                        </FloatingLabel> <br />
                        <FloatingLabel label="Color">
                            <Form.Control 
                                type="color" 
                                value={color} 
                                onChange={(e) => setColor(e.target.value)} 
                                style={{width: "4rem", height: "4rem"}}
                            />
                        </FloatingLabel> <br />
                    </Form>
                    <br />
                    {props.mode === "Edit" ? (
                        <Button 
                            variant="outline-danger" 
                            onClick={() => deleteSubject(props.subject.id)}
                        >
                            Delete subject
                        </Button>
                    ):(null)}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleClose}>
                        Cancel
                    </Button>
                    {props.mode === "Create" ? (
                        <Button variant="success" onClick={() => newSubject(name, shortName, color)}>
                            Create
                        </Button>
                    ):(
                        <Button variant="success" onClick={() => editSubject(name, shortName, color)}>
                            Save changes
                        </Button>
                    )}
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default SubjectManagement