function GradeShow(props) {

    function renderColor(grade) {
        if (grade === 1) return "#4CAF50"
        if (grade === 2) return "#8BC34A"
        if (grade === 3) return "#FBC02D"
        if (grade === 4) return "#FF9800"
        if (grade === 5) return "#F44336"
        else return "#9C0202"
    }

    return (
        <p  
            key={props.grade.id} 
            style={{    
                fontWeight: `${props.grade.weight}00`,
                fontSize: `${(20+props.grade.weight)}px`, 
                color: `${renderColor(Math.round(props.grade.grade))}`,
                margin: 0
            }}
        >
            {Math.round(props.grade.grade)}
        </p>
    )
}

export default GradeShow

