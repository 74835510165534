import React, {useState, useEffect} from "react"
import {Table, OverlayTrigger, Tooltip} from "react-bootstrap"
import moment from "moment"
import GradeShow from "./GradeShow";
import AverageGrade from "./AverageGrade"
import NoGrades from "./NoGrades";
import { GradeList } from "../api/calls";

function StudentGradeList(props) {

    const [data, setData] = useState([])

    useEffect(() => {
        const fetchGrades = async () => {
            const data = await GradeList(props.subjectId, props.studentId)
            if (data) {setData(data)}
            else setData([])
        }
        fetchGrades()
    }, [props.studentId, props.subjectId, props.id])

    return(
        <div className="Grade">
            {data.length == 0 ? (
                <NoGrades />
            ):(
                <Table>
                    <tbody>
                        {data.grades.map(grade => (
                            <tr key={grade.id} className="GradeRow">
                                <td>{moment(grade.date).format("DD. MM.")}</td>
                                <td>{grade.description}</td>
                                <OverlayTrigger placement="top" overlay={<Tooltip id="button-tooltip-2">Weight: {grade.weight}</Tooltip>}>
                                    <td><GradeShow grade={grade}/></td>
                                </OverlayTrigger>
                            </tr>
                        ))}
                        <tr>
                            <td/><td/>
                            <td>
                                <AverageGrade average={data.average} mode={"user"}/>
                            </td>
                        </tr>
                    </tbody>
                </Table>
            )}
        </div>
    )
}

export default StudentGradeList