import React from "react";
import Icon from '@mdi/react';
import { mdiAccountTie, mdiAccountSchool, mdiFaceWomanShimmer, mdiArrowRightBold } from '@mdi/js';
import {Link} from "react-router-dom"

function UserCards(props) {

    const studentText = "Ermöglicht das Einsehen der Noten aller Fächer, die der Schüler belegt hat."
    const teacherText = "Der Lehrer kann Noten für einzelne Schüler eintragen oder Noten gleichzeitig für alle Schüler einer Klasse festlegen"
    const principalText = "Der Direktor kann Schüler, Lehrer und FFächer erstellen, bearbeiten und löschen."

    const icon = props.type === "Student" ? (mdiAccountSchool) : props.type === "Teacher" ? (mdiFaceWomanShimmer) : (mdiAccountTie)
    const text = props.type === "Student" ? (studentText) : props.type === "Teacher" ? (teacherText) : (principalText)
    const href = props.type.toLowerCase()
    const header = props.type === "Student" ? "Student" : props.type === "Teacher" ? "Lehrer" : "Direktor"


    return(
        <Link to={href} style={{textDecoration: "none"}}>
            <div className="UserCard">
                <div className="UserCardSections">
                    <Icon path={icon} size={5}  className="RoleIcon"/>
                    <div className="UserCardText">
                        <h3>{header}</h3>
                        <p>{text}</p>
                    </div>
                    <button onClick={props.onSelect}>
                        <Icon path={mdiArrowRightBold} size={3} color="#3a4a58" className="Icon"/>
                    </button>
                </div>
            </div>
        </Link>
    )
}

export default UserCards
