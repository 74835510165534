import React, {useState, useEffect} from "react"
import { Card, Spinner } from "react-bootstrap"
import StudentGradeList from "./StudentGradeList"
import { useStudentId } from "./StudentIdContext"
import { SubjectList } from "../api/calls"

function StudentSubjectList(props) {

    const [data, setData] = useState([])
    const {id} = useStudentId()
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const fetchSubjects = async () => {
            const data = await SubjectList()
            if (data) {
                setData(data)
                setLoading(false)
            }
        }
        fetchSubjects()
    }, [props.id])


    return(
        <div className="Subjects">
            <div className="SmallHeader">
                <h1>Noten</h1>
            </div>
            <div className="SubjectsContainer">
                {loading ? (<Spinner style={{ width: '5rem', height: '5rem', marginTop:"10rem" }}/>):(
                    <>
                        {data.map(subject => (
                            <Card key={subject.id} className="StudentSubjectCard">
                                <Card.Header style={{backgroundColor: `${subject.color}33`}}>
                                    <div className="SubjectContainer">
                                        <h1 className="SubjectName" style={{backgroundColor: `${subject.color}99`}}>{subject.short_name}</h1>
                                        <h3 style={{}}>{subject.name}</h3>
                                    </div> 
                                </Card.Header>
                                <Card.Body>
                                    <StudentGradeList subjectId={subject.id} studentId={id}/>
                                </Card.Body>
                            </Card>
                        ))}
                    </>
                )}
            </div>
        </div>
    )
}

export default StudentSubjectList