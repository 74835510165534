import React from "react";
import UserCards from "../components/UserCards";
import landingImage from "../images/landing.jpg"

function MainPage() {
    return (
        <div className="MainPage">

            <h1 className="Logo">NoteBuzz</h1>
            <h3>Dein studium. Einfach gemacht.</h3>
            
            <div className="MainPageContainer">
                

                <div className="MainPageCardContainer">
                    <UserCards type={"Student"} />
                    <UserCards type={"Teacher"} />
                    <UserCards type={"Principal"} />
                </div>
                <img src={landingImage} alt="deco" className="LandingImage"/>
            </div>
        </div>
    )
}

export default MainPage