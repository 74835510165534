import React from "react";
import PrincipalStudents from "../components/PrincipalStudentsList";
import PrincipalSubjects from "../components/PrincipalSubjectList";
import ScheduleViewer from "../components/ScheduleViewer";

function PrincipalPage() {


    return(
        <div className="PrincipalPage">
            <div className="Header">
                <h1 className="SmallLogo">NoteBuzz</h1>
                <h1>Direktor editor</h1>
            </div>
            <div className="PrincipalPageContent">
                <div className="PrincipalPageItem1">
                    <PrincipalStudents/>
                    <ScheduleViewer role={"Principal"}/>
                    
                </div>
                
                <div className="PrincipalPageItem2">
                    <PrincipalSubjects />
                </div>
            </div>
        </div>
    )
}

export default PrincipalPage