import React from "react"
import { Card, OverlayTrigger, Tooltip } from "react-bootstrap"
import moment from "moment"
import EditGrade from "./EditGrade"
import GradeShow from "./GradeShow"


function TeacherGrades(props) {

    return(
        <>
            {props.grades.length !== 0 ? (
                <>
                    {props.grades.map(grade => (
                        <Card key={grade.id} className="TeacherCardItem" border="light">
                            <Card.Header>
                                <GradeShow grade={grade}/>
                            </Card.Header>
                            <OverlayTrigger placement="top" overlay={<Tooltip>Weight: {grade.weight} <br /> Date: {moment(grade.date).format("DD. MM.")}</Tooltip>}>
                                <Card.Body>{grade.description} <br /></Card.Body>  
                            </OverlayTrigger>
                            <EditGrade grade={grade} onUpdate={props.onUpdate}/>
                        </Card>
                    ))}
                </>
            ) : ("Noch keine Noten")}
        </>
    )
}

export default TeacherGrades