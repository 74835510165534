import React, { useState } from "react"
import { Form, Button, Modal, FloatingLabel } from "react-bootstrap"
import Icon from "@mdi/react"
import { mdiPlusBoxMultipleOutline } from "@mdi/js"
import { toast } from "react-toastify"
import GradeWeight from "./GradeWeight"
import GradeSelector from "./GradeSelector"
import { CreateNewGrade } from "../api/calls"

function CreateMultipleGrades(props) {

    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)

    const [grades, setGrades] = useState(
        props.students.map((student) => ({ id: student.id, grade: 0 }))
    )
    const [weight, setWeight] = useState(0)
    const [description, setDescription] = useState("")

    const handleGradeChange = (studentId, newGrade) => {
        setGrades((prevGrades) =>
            prevGrades.map((g) =>
                g.id === studentId ? { ...g, grade: newGrade } : g
            )
        )
    }

    const handleAddGrades = () => {
        grades
            .filter((studentGrade) => studentGrade.grade > 0)
            .forEach(async (studentGrade) => {
                await CreateNewGrade(
                    studentGrade.id,
                    props.subject,
                    description,
                    studentGrade.grade,
                    weight
                )
            })
        toast.success(`Neue Noten wurden hinzugefügt: (${grades.length}x)`, {position: "top-center"})
        handleClose()
        setGrades(props.students.map((student) => ({ id: student.id, grade: 0 })))
        setDescription("")
        setWeight(0)
        props.onUpdate()
    }

    return (
        <div className="NewGrade">
            <div className="NewGradeHeader">
                <h2>Noten</h2>
                <Button variant="warning" onClick={handleShow}>
                    <Icon path={mdiPlusBoxMultipleOutline} size={1} />
                </Button>
            </div>

            <Modal show={show} onHide={handleClose} centered size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        Mehrere Noten eintragen - <b> {props.subjectName}</b>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <FloatingLabel label="Description">
                            <Form.Control
                                type="text"
                                placeholder="example: Test"
                                onChange={(e) => setDescription(e.target.value)}
                            />
                            </FloatingLabel>
                        </Form.Group>
                        <br/>
                        <h5>Weight</h5>
                            <GradeWeight setWeight={(num) => setWeight(num)} weight={weight}/>
                        <br/><br/>
                        <h5>Grades</h5>
                        {props.students.map((student) => (
                            <div key={student.id} className="ModalGradesInput">
                                <h6 style={{width: "30%"}}>{student.surname} {student.name}</h6>
                                <GradeSelector 
                                    grades={grades} 
                                    student={student} 
                                    mode={"all"} 
                                    onChange={(id, num) => handleGradeChange(id, num)}
                                />
                                <br/>
                            </div>
                        ))}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleClose}>Back </Button>
                    <Button variant="success" onClick={handleAddGrades}>Add </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default CreateMultipleGrades
