import React, {useState, useEffect} from "react";
import {Modal, Button} from "react-bootstrap"
import Icon from "@mdi/react"
import { mdiPencilBoxOutline, mdiPlusBoxOutline } from "@mdi/js"
import { SubjectList, DeleteSchedule, AddSchedule, EditSchedule } from "../api/calls"

function ScheduleManagement(props) {

    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)

    const [subjects, setSubjects] = useState([])

    useEffect(() => {
        const fetchSubjects = async () => {
            const data = await SubjectList()
            if (data) setSubjects(data)
        }
        fetchSubjects()
    }, [])

    const NewSchedule = async (day, hour, subject_id) => {
        try {
            await AddSchedule(day, hour, subject_id)
        } finally {
            props.onUpdate()
            handleClose()
        }   
    }

    const ChangeSchedule = async (schedule_id, subject_id) => {
        try {
            await EditSchedule(schedule_id, subject_id)
        } finally {
            props.onUpdate()
            handleClose()
        }   
    } 

    const deleteSchedule = async (id) => {
        try {
            await DeleteSchedule(id)
        } finally {
            handleClose()
            props.onUpdate()
        }
    }

    return (
        <div>
            {props.mode === "Add" ? (
                <Button variant="success" onClick={handleShow}>
                    <Icon path={mdiPlusBoxOutline} size={1} />
                </Button>
            ):(
                <Button variant="secondary" onClick={handleShow}>
                    <Icon path={mdiPencilBoxOutline} size={1} />
                </Button>
            )}
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                        <Modal.Title>
                            {props.mode === "Add" ? ("Add new subject"):("Edit subject")}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <h6>Day: <b>{props.day}</b>, Hour: <b>{props.hour}</b></h6>
                        <br/>
                        {subjects.map(subject => (
                                <>
                                <button
                                    style={{margin: "5px", padding: "8px", backgroundColor: `${subject.color}85`, border: subject.color, borderRadius:"5px"}}
                                    onClick={
                                        props.mode === "Add" 
                                        ? () => NewSchedule(props.day, props.hour, subject.id) 
                                        : () => ChangeSchedule(props.scheduleId, subject.id)
                                    }
                                >
                                    {subject.name}
                                </button>
                                </>
                        ))}
                        <br/><br/>
                        {props.mode === "Edit" ? (
                                    <Button variant="outline-danger" onClick={() => deleteSchedule(props.scheduleId)}>
                                        Freie Stunde - Pause
                                    </Button>
                                ):(null)}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={handleClose}>
                            Cancel
                        </Button>
                        {props.mode === "Add" ? (
                            <Button variant="success">
                                Create
                            </Button>
                        ):(
                            <Button variant="success">
                                Save changes
                            </Button>
                        )}
                    </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ScheduleManagement
