import {toast} from "react-toastify"
import moment from "moment"

const URL = 'https://todoapi.sivakm.com/api'

//  STUDENTS

export const StudentList = async () => {
    try {
        const response = await fetch(`${URL}/student/list`)
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`)
        }
        const result = await response.json()
        return(result)
    } catch (error) {
        console.error('Error fetching data:', error)
    }
}

export const NewStudent = async (name, surname, birthday, address) => {
    try {
        const dToIn = {
            name: name,
            surname: surname, 
            birthday: birthday,
            address: address,
        }
        const response = await fetch(`${URL}/student/createStudent`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(dToIn)
        })

        const data = await response.json();
        toast.success(`Neuer Student wurde erstellt!`, {position: "top-center"})
        return data;
    } catch (error) {
        console.error('Error creating new Student:', error);
    }
}

export const DeleteStudent = async(id) => {
    try {
        const response = await fetch(`${URL}/student/deleteStudent?id=${id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify()
        })

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData);
        }
        const data = await response.json();
        toast.success(`Student wurde gelöscht!`, {position: "top-center"})
        return data;
    } catch (error) {
        console.error('Error deleting:', error);
    }
}

export const EditStudent = async (id, name, surname, birthday, address) => {
    try {
        const dToIn = {
            name: name,
            surname: surname, 
            birthday: moment(birthday).format("YYYY-MM-DD"),
            address: address
        }
        const response = await fetch(`${URL}/student/updateStudent?id=${id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(dToIn)
        })

        const data = await response.json();
        toast.success(`Student wurde erfolgreich bearbeitet.`, {position: "top-center"})
        return data;
    } catch (error) {
        console.error('Error editing new Student:', error);
    }
}


// GRADES

export const GradeList = async (subject, student) => {
    try {
        const response = await fetch(`${URL}/grade/list?subject=${subject}&student=${student}`)
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`)
        }
        const result = await response.json()
        return(result)
    } catch (error) {
        console.error('Error fetching data:', error)
    }
}

export const GetAverage = async (subject, student) => {
    try {
        const response = await fetch(`${URL}/grade/average?subject=${subject}&student=${student}`)
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`)
        }
        const result = await response.json()
        return(result)
    } catch (error) {
        console.error('Error fetching data:', error)
    }
}

export const CreateNewGrade = async(student, subject, description, grade, weight) => {
    try {
        const dToIn = {
            grade: grade,
            weight: weight,
            subject_id: subject,
            description: description || "?",
            student_id: student
        }
        const response = await fetch(`${URL}/grade/create`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(dToIn)
        })

        const data = await response.json();
        return data
    } catch (error) {
        console.error('Error creating new Grade:', error);
        toast.error(`Note könnte nicht erstellt werden.`, {position: "top-center"})
    }
}

export const DeleteGrade = async (id) => {
    try {
        const response = await fetch(`${URL}/grade/delete?id=${id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify()
        })

        if (!response.ok) {
            const errorData = await response.json()
            throw new Error(errorData)
        }
        const data = await response.json()
        toast.success(`Note wurde gelöscht!`, {position: "top-center"})
        return data
    } catch (error) {
        toast.error(`Note könnte nicht gelöscht werden.`, {position: "top-center"})
        console.error('Error deleting:', error)
    }
}

export const UpdateGrade = async (id, description, grade, weight) => {
    try {
        const dToIn = {
            grade: grade,
            weight: weight,
            description: description || "?",
        }
        const response = await fetch(`${URL}/grade/edit?id=${id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(dToIn)
        })

        const data = await response.json()
        toast.success(`Note wurde bearbeitet!`, {position: "top-center"})
        return data;
    } catch (error) {
        toast.error(`Note könnte nicht bearbeitet werden`, {position: "top-center"})
        console.error('Error editing new Grade:', error)
    }
}

// SUBJECTS

export const SubjectList = async () => {
    try {
        const response = await fetch(`${URL}/subject/list`)
        const result = await response.json()
        return(result)
    } catch (error) {
        console.error('Error fetching data:', error)
    }
}

export const NewSubject = async (name, short_name, color) => {
    try {
        const dToIn = {
            name: name,
            short_name: short_name, 
            color: color,
            teacher: "1",
        }
        const response = await fetch(`${URL}/subject/create`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(dToIn)
        })

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData);
        }
        const data = await response.json();
        toast.success(`Neuer Fach wurde erstellt!`, {position: "top-center"})
        return data;
    } catch (error) {
        console.error('Error creating new Subject:', error);
    }
}

export const EditSubject = async (id, name, short_name, color) => {
    try {
        const dToIn = {
            name: name,
            short_name: short_name, 
            color: color
        }
        const response = await fetch(`${URL}/subject/edit?id=${id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(dToIn)
        })

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData);
        }
        const data = await response.json();
        toast.success(`Fach wurde erfolgreich bearbeitet.`, {position: "top-center"})
        return data;
    } catch (error) {
        console.error('Error editing a Subject:', error);
    }
}

export const DeleteSubject = async (id) => {
    try {
        const response = await fetch(`${URL}/subject/delete?id=${id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify()
        })

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData);
        }
        const data = await response.json();
        toast.success(`Fach wurde gelöscht!`, {position: "top-center"})
        return data;
    } catch (error) {
        console.error('Error deleting:', error);
    }
}

// SCHEDULE

export const GetSchedule = async () => {
    try {
        const response = await fetch(`${URL}/schedule/list`)
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`)
        }
        const result = await response.json()
        return result
    } catch (error) {
        console.error('Error fetching data:', error)
    } 
}


export const EditSchedule = async (id, subject_id) => {
    try {
        const dToIn = {
            subject_id: subject_id,
        }
        const response = await fetch(`${URL}/schedule/edit?id=${id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(dToIn)
        })

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData);
        }
        const data = await response.json();
        toast.success(`Stundenplan wurde erfolgreich bearbeitet.`, {position: "top-center"})
        return data;
    } catch (error) {
        console.error('Error editing a Subject:', error);
    }
}

export const AddSchedule = async (day, hour, subject_id) => {
    try {
        const dToIn = {
            day: day,
            hour: hour,
            subject_id: subject_id
        }
        const response = await fetch(`${URL}/schedule/add`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(dToIn)
        })

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData);
        }
        const data = await response.json();
        toast.success(`Stundenplan wurde erfolgreich bearbeitet.`, {position: "top-center"})
        return data;
    } catch (error) {
        console.error('Error creating new Schedule:', error);
    }
}

export const DeleteSchedule = async (id) => {
    try {
        const response = await fetch(`${URL}/schedule/delete?id=${id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            }, 
            body: JSON.stringify()
        })

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData);
        }
        const data = await response.json();
        toast.success(`Stunde wurde vom Stundenplan erfolgreich gelöscht!`, {position: "top-center"})
        return data;
    } catch (error) {
        console.error('Error deleting:', error);
    }
}