import React from "react"
import { Button, ButtonGroup } from "react-bootstrap"
import Icon from "@mdi/react"
import { mdiNumeric1, mdiNumeric2, mdiNumeric3, mdiNumeric4, mdiNumeric5, mdiNumeric6 } from "@mdi/js"


function GradeSelector(props) {

    function getPath (num) {
        if (num === 1) return mdiNumeric1
        if (num === 2) return mdiNumeric2
        if (num === 3) return mdiNumeric3
        if (num === 4) return mdiNumeric4
        if (num === 5) return mdiNumeric5
        else return mdiNumeric6
    }

    function renderColor(num) {
        if (num === 1) return "#4CAF50"
        if (num === 2) return "#8BC34A"
        if (num === 3) return "#FBC02D"
        if (num === 4) return "#FF9800"
        if (num === 5) return "#F44336"
        else return "#9c0202"
    }

    return(
        <ButtonGroup style={{ width: props.mode === "all" ? "70%" : "100%" }}>
                {[1, 2, 3, 4, 5, 6].map((num) => (
                    <Button
                        key={num}
                        variant={ props.mode === "all" ? (props.grades.find((g) => g.id === props.student.id).grade === num ? "secondary" : "light"):(props.grade === num ? "secondary" : "light")}
                        onClick={ props.mode === "all" ? (() => props.onChange(props.student.id, num)):(() => props.onChange(num))}
                    >
                        <Icon path={getPath(num)} size={2} color={renderColor(num)} />
                    </Button>
                ))}
            
        </ButtonGroup>
    )
}

export default GradeSelector