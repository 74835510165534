import React from "react";
import TeacherSubjects from "../components/TeacherSubjects";

function TeacherPage() {

    return(
        <div className="TeacherPage">
            <div className="Header">
                <h1 className="SmallLogo">NoteBuzz</h1>
                <h1>Teacher editor</h1>
            </div>
            <TeacherSubjects />
        </div>
    )
}

export default TeacherPage