import React, {useState, useEffect} from "react"
import { Table } from "react-bootstrap"
import moment from "moment"
import StudentManagement from "./StudentManagement"
import { StudentList } from "../api/calls"

function PrincipalStudentsList() {

    const [students, setStudents] = useState([])
    const [newStudent, setNewStudent] = useState(0)

    useEffect(() => {
        const fetchStudents = async () => {
            const data = await StudentList()
            if (data) {setStudents(data)}
        } 
        fetchStudents()
    }, [newStudent])

    return(
        <div className="StudentsList">
            <div className="PrincipalHeader">
                <h2>Students</h2>
                <StudentManagement onUpdate={() => setNewStudent(newStudent + 1)} mode="Create"/>
            </div>
            <Table hover responsive>
                <thead>
                    <th>Surname</th>
                    <th>Name</th>
                    <th>Age</th>
                    <th>Birthday</th>
                    <th>Address</th>
                    <th></th>
                </thead>
                <tbody>
                    {students.map(student => (
                        <tr key={student.id}>
                            <td>{student.surname}</td>
                            <td>{student.name}</td>
                            <td><>{moment().diff(moment(student.birthday), 'years')}</></td>
                            <td>{moment(student.birthday).format("DD. MM. YYYY")}</td>
                            <td>{student.address}</td>
                            <td style={{width: "5rem"}}>
                                <StudentManagement 
                                    onUpdate={() => setNewStudent(newStudent + 1)} 
                                    student={student} 
                                    mode="Edit"
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    )
}

export default PrincipalStudentsList
