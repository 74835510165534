import React, { useState, useEffect } from "react"
import { useStudentId } from "./StudentIdContext"
import { Button } from "react-bootstrap"
import { StudentList } from "../api/calls"

function UserSwitcher() {

    const [data, setData] = useState([])


    useEffect(() => {
        const fetchData = async () => {
            const data = await StudentList()
            if (data) {setData(data)}
        } 
        fetchData()
    }, [])

    const {id, changeStudentId} = useStudentId()
    const changeId = (newId) => {
        changeStudentId(newId)
    }

    return(
        <div className="chooseUser">
            {data.map(student => (
                <Button 
                    className="TextColor" 
                    key={student.id} 
                    variant={id === student.id ? ("secondary"):("outline-Secondary")}
                    onClick={() => changeId(student.id)}
                >
                    {student.name} {student.surname}
                </Button>
            ))}
        </div>
    )
}

export default UserSwitcher