import Icon from '@mdi/react';
import { mdiNumericOff } from '@mdi/js';

function NoGrades() {
    return(
        <div>
            <Icon path={mdiNumericOff} size={3} color="gray" />
            <br/><br/>
            <p>Keine Noten</p>
        </div>
    )
}

export default NoGrades